<template lang="pug">
  #users-list
    b-row
      b-col(lg="6").my-1
        b-form-group
          b-form-input(
            v-model="filter"
            type="search"
            id="filterInput"
            placeholder="Buscar Usuario"
          )
            b-input-group-append
              b-button(:disabled="!filter" @click="filter = ''") Clear
      b-col(sm="7" md="6" class="my-1")
        b-pagination(
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        )
    b-table(
      hover
      outlined
      :items="users"
      :fields="fields"
      :filter="filter"
      :filter-included-fields="filterOn"
      :current-page="currentPage"
      :per-page="perPage"
      primary-key="id"
      table-variant="dark"
    )
      template(v-slot:cell(actions)="row")
        b-button(size="sm" @click="modalEdit(row.item, $event.target)" class="mr-1") Editar
    b-modal(id="editUser" :title="dataModal.title" ok-only hide-footer)
      user-edit(:user="dataModal.item" @updatingUser="updateUser")
</template>
<script>
import UserEdit from "./edit"

export default {
  name:"UsersList",
  components:{
    UserEdit
  },
  data() {
    return {
      users: [],
      fields: [
        { key: 'id', label: '#', sortable: true },
        { key: 'name', label: 'Nombre Completo', sortable: true },
        { key: 'phone', label: 'Teléfono', sortable: true },
        { key: 'email', sortable: true },
        { key: 'role.name', label:'Rol', sorteable: true},
        { key: 'actions', label: 'Actions' }
      ],
      filter: '',
      filterOn: [],
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      dataModal: {
        id: 'info-modal',
        title: '',
        item: {}
      }
    }
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    async getUsers() {
      const { data } = await this.$axios.get('/api/v1/users')
      this.users = data
      this.totalRows = this.users.length
    },
    updateUser(user){
      this.users[user.id] = user
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.role_id === 3) return 'table-success'
      if (item.role_id === 2) return 'table-primary'
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    modalEdit(item, button) {
      this.dataModal.title = `Edición de usuario ${item.name}`
      this.dataModal.item = item
      this.$root.$emit('bv::show::modal', "editUser", button)
    },
    resetdataModal() {
      this.dataModal.title = ''
      this.dataModal.item = {}
    },
  }
}
</script>
<style lang="scss">
  .custom-file-label {
    border-radius: 20px !important;
  }
  .page-link {
    background: white;
  }
  .table{
    border-radius: 10px;
    overflow: hidden;
  }
</style>