<template lang="pug">
.dashboard
  .dashboard-sidebars(sticky="true").d-none.d-sm-block.d-md-block
    SidebarUser
  .dashboard-content
    b-container
      b-row
        b-col.pt-4
          h3 Usuarios
          users-list
    FooterNavbarMobile
</template>
<script>
  import { mapState } from "vuex"
  import SidebarUser from "@/components/dashboard/SidebarUser";
  import FooterNavbarMobile from "@/components/FooterNavbarMobile"
  import UsersList from "@/components/users/List"

  export default {
    name:"users",
    components: {
      SidebarUser,
      FooterNavbarMobile,
      UsersList
    },
    created() {
      if(!this.isAdmin){
        this.$router.push({ name: "not-found"});
      }
    },
    computed: {
      ...mapState("auth",["isAdmin"])
    }
  }
</script>
<style lang="scss">
  @import "src/assets/scss/variables";

  .dashboard {
    align-items: stretch;
    display: flex;
    &-sidebars {
      padding-top: 30px;
      width: 150px;
    }
    &-content {
      width: 100%;
      min-height: 100vh;
      background-color:rgba(236,239,241,.6);
      .nav-tabs{
        .nav-link{
          color: #8b7660;
          text-transform: uppercase;
          font-size: .75rem;
          font-weight: 700;
          &.active{
            background-color: transparent;
            color: $primary;
            border-color: transparent;
            border-bottom: solid 2px $primary;
          }
        }
      }
    }
  }
</style>