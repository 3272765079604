<template lang="pug">
#edit-user
  b-form-group
      label Nombre:
      b-form-input(placeholder="Nombre" v-model="localUser.name")
  b-form-group
    label Teléfono:
    b-form-input(type="tel" placeholder="Teléfono" v-model="localUser.phone")
  b-form-group
    label Email:
    b-form-input(type="email" placeholder="Email" v-model="localUser.email")
  b-form-group
    label Avatar:&nbsp;
      a(v-if="localUser.avatar" :href="localUser.avatar") Ver actual
    b-form-file(accept="image/*" placeholder="Seleccionar imagen" browse-text="Examinar")
  b-form-group
    label Banner:&nbsp;
      a(v-if="localUser.image" :href="localUser.image") Ver actual
    b-form-file(accept="image/*" placeholder="Seleccionar imagen" browse-text="Examinar")
  b-form-group
    label Rol de acceso:
    b-form-select(
      v-model="localUser.role_id"
      :options="roles"
      value-field="id"
      text-field="name"
    )
  b-button(
    class="mt-3"
    block
    variant="outline-mhmotors"
    @click="changeUser"
  )
    font-awesome-icon(icon="save")
    |  Guardar
</template>
<script>
export default {
  name:"UserEdit",
  props:{
    user:{
      id:{
        type:Number,
        required: true
      },
      name:{
        type:String,
        required:true
      },
      phone:{
        type:Number,
        required: false
      },
      email:{
        type: String,
        requried:true
      },
      avatar:{
        type:String,
        required: false
      },
      image:{
        type:String,
        required: false
      },
      role:{
        id:{
          type: Number,
          required:true,
        },
        name:{
          type: String,
          required: true,
        },
        permission_id:{
          type:Number,
          required: true,
        }
      },
    }
  },
  data() {
    return {
      roles:[
        { id:1, name:"Particular", permission_id:0 },
        { id:2, name:"Profesional", permission_id:0 },
        { id:3, name:"Administrador", permission_id:0 },
        { id:3, name:"Moderador", permission_id:0 },
      ],
      localUser: {
        name: this.user.name,
        phone: this.user.phone,
        email: this.user.email,
        avatar: this.user.avatar,
        image: this.user.image,
        role_id: this.user.role.id,
      }
    }
  },
  methods: {
    changeUser() {
      this.thereChanges()
      this.saveUser()
      this.$emit('updatingUser', this.localUser)
    },
    thereChanges(){
      this.localUser.name == this.user.name ?? delete this.localUser.name
      this.localUser.phone == this.user.phone ?? delete this.localUser.phone
      this.localUser.email == this.user.email ?? delete this.localUser.email
      this.localUser.avatar == this.user.avatar ?? delete this.localUser.avatar
      this.localUser.image == this.user.image ?? delete this.localUser.image
      this.localUser.role_id == this.user.role.id ?? delete this.localUser.role_id
      console.log('delete', this.localUser);
    },
    async saveUser(){
      console.log(this.localUser)
      const { data } = await this.$axios.post('/api/v1/user',this.localUser)
      console.log('response', data)
      this.localUser = data
    }
  }
}
</script>